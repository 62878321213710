import moment from "moment";
import Book from "./book";
import Formative from "./formative";
import { useContext, useEffect, useRef, useState } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import PowerStandard from "components/common/svgs/power-standard";
import Skeleton from "react-loading-skeleton";
import CollapsedFormatives from "./collapsedFormatives";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NoStudents } from "components/classroom/modals/noStudents";
import { ChooseQuestionOrder } from "components/classroom/modals/chooseQuestionOrder";
import { assignFormatives } from "redux/actionCreators/classroom";
import RemediationModal from "../remediateModal";
import { NewAttemptModal } from "../newAttemptModal";
import NewAttemptInfoModal from "../newAttemptInfoModal";
import useWindowScrollPosition from "hooks/useWindowScrollPosition";
import { SCROLL_VALUE_SUMMATIVE } from "util/constants";
import { saveFormative } from "util/index";
// import ToolTip from "components/common/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import LeftArrowWhite from "components/common/svgs/left-arrow-white";
import OverlapingRectangles from "components/common/svgs/overlaping-rectangles";
import CalenderIcon from "components/common/svgs/calender";
import HtmlParser from "components/common/htmlParser";
import { Assessment as assesment, ReportsNavigation } from "interfaces/professionalDevelopment";
import Toggle from "components/common/Toggle";
import ScrollingText from "components/common/ScrollingText";


const Assessment = ({ formative, open, setOpen, formativeLoading, filter }: any) => {
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  const {
    id,
    name,
    description,
    scheduled_date,
    continuous_practice,
    independent_practice,
    lesson_guided_practice,
    students_assessed_count,
    students_total_count,
    scheduled_color,
    power_standard, 
  } = formative;

  const {
    fetchFormativeRemediateData,
    fetchAssessmentMonitorData,
    reEnableAssessments,
    formativeRemediateList,
    markAssessmentCompleted,
    fetchFormatives,
    schoolId,
    isLoading: loading,
    setFormatives,
    formatives,
    classroomId,
    pdClassroom, 
    navigateTo
  } = useContext(ProfessionalDevelopmentContext);
  const isLoading = loading || formativeLoading;
  // const isOpened = openedAccordians?.findIndex((acc: number) => acc === formative.id) > -1;
  const scrollValue = useWindowScrollPosition(
    SCROLL_VALUE_SUMMATIVE,
    !isLoading
  );

  const [searchParams] = useSearchParams();
  const [, setOpenRemediateModal] = useState(
    searchParams.get("openRemediateModal") === "true"
  );

  const newAttemptModalRef = useRef<any>(null!);
  const modalRef = useRef<any>(null);
  const infoModalRef = useRef<any>(null);

  const [showNewAttemptModal, setShowNewAttemptModal] = useState(false);
  const [openNoStudentsModal, setOpenNoStudentsModal] = useState(false);
  const [openRandomModal, setOpenRandomModal] = useState(false);
  const [isFlag, setFlag] = useState(false);
  const [showRemmediationModal, setShowRemmediationModal] = useState<any>(
    null!
  );
  const [formativeId, setFormativeId] = useState<number>(null!);
  const [newAttemptInfoModal, setNewAttemptInfoModal] = useState(false);
  

  const handleOnChangeToggleFormative = (
    e: any,
    show_random_option: boolean
  ) => {
    if (!formatives[0].students_total_count) {
      setOpenNoStudentsModal(true);
      return;
    }

    const currentToggleState = e.target.checked;

    const updatedFormatives = formatives.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          enabled: currentToggleState,
        };
      }
      return item;
    });

    setFormatives(updatedFormatives);

    if (show_random_option && e.target.checked) {
      setOpenRandomModal(true);
    } else {
      handleAvaibilityToggle(formative, e.target.checked);
    }
  };

  const switchFlag = () => {
    setFlag(!isFlag);
  };

  const handleAvaibilityToggle = async (x: any, checked: any) => {
    await assignFormatives(
      {
        classroom_id: classroomId,
        formative_id: x.id,
        is_enabled: checked,
        is_random: x.show_random_option,
      },
      switchFlag
    );
    fetchAssessmentMonitorData();
    fetchFormatives(schoolId, classroomId, filter);

    if (checked) {
      if (document.getElementById(`monitor-$${x.id}`) as HTMLInputElement) {
        (
          document.getElementById(`monitor-$${x.id}`) as HTMLInputElement
        ).disabled = false;
      }
    } else {
      if (document.getElementById(`monitor-$${x.id}`) as HTMLInputElement) {
        (
          document.getElementById(`monitor-$${x.id}`) as HTMLInputElement
        ).disabled = true;
      }
    }
  };

  const onCancelChooseQuestionModal = () => {
    // const { id } = checkboxRef.current as any
    const reports = formatives;
    const reportIndex = reports.findIndex((repo: any) => repo.id === id);
    if (reportIndex > -1) {
      const tempReports = [...reports];
      tempReports[reportIndex].enabled = false;
      setFormatives(tempReports);
    }
    setOpenRandomModal(false);
  };

  const onChangeRandom = async (x: any) => {
    await handleAvaibilityToggle(x, true);
    // setTimeout(() => {
    //     (false);
    // }, 1000);
    setOpenRandomModal(false);
  };

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (formative) {
      setCompleted(formative.is_completed);
    }
  }, [formative]);

  const onMarkCompleted = (e: any, report: any) => {
    setCompleted(e.target.checked);
    markAssessmentCompleted({
      isSummative: false,
      status: e.target.checked,
      formative_id: report.id,
      classroom_id: classroomId,
      moved: e.target.checked,
    });
  };

  const toggleAccordian = () => {
    // setAddDropdownIndicatorClass(false);
    setOpen(id);
  };

  const onClickRemediate = (formativeId: any) => {
    setShowRemmediationModal(true);
    setFormativeId(formativeId);
  };

  useEffect(() => {
    const handleClick = (event: any) => {
      if (!newAttemptModalRef.current?.contains(event.target)) {
        setShowNewAttemptModal(false);
        setShowRemmediationModal(null!);
      }
    };

    if (!showNewAttemptModal) {
      document.removeEventListener("click", handleClick, true);
      return;
    }

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [showNewAttemptModal]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (!infoModalRef.current?.contains(event.target)) {
        if (infoModalRef.current) {
          setNewAttemptInfoModal(false);
          return;
        }
      }

      if (!modalRef.current?.contains(event.target)) {
        if (infoModalRef.current?.contains(event.target)) {
          return;
        }

        setShowRemmediationModal(false);
        return;
      }
    };

    if (!showRemmediationModal) {
      document.removeEventListener("click", handleClick, true);
      return;
    }

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [showRemmediationModal]);

  useEffect(() => {
    if (loading || formativeLoading || !formatives?.length) return;

    const allNameElements = document.querySelectorAll("#formative-name");
    // console.log('allNameElements', allNameElements);

    let maxWidth = 0;
    allNameElements.forEach((assessment) => {
      const client = assessment.getBoundingClientRect();
      if (client.width > maxWidth) {
        maxWidth = client.width;
      }
    });
    //setMaxWidth(maxWidth);
  }, [loading, formativeLoading, formatives]);

  const isMarkCompletedDisabled =
    Math.floor((students_assessed_count / students_total_count) * 100) < 50;

  const formattedDate = scheduled_date
    ? moment(scheduled_date).format("dddd, MMM DD")
    : "Not Scheduled";

  const isAtleastOnePowerStandard = formatives.some(
    (item) => item.power_standard
  );

  const navigateToPacingCalendar = (e: any)=>{
    e.stopPropagation();
    if(pdClassroom.is_enabled && !isLoading){
    navigate("/pacing-calendar", {
      state: { date: scheduled_date },
    });
    saveFormative(open, formative);
    sessionStorage.setItem(
      SCROLL_VALUE_SUMMATIVE,
      `${scrollValue}`
    );
  }
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            !open && setOpen();
          }
        }}
        onClick={() => {
          !open && setOpen();
        }}
        className={`accordion-container ${
          open ? "" : "accordion-container-animation"
        }`}
      >
        <table>
          <thead
            className={`accordion-header ${
              open ? "accordion-header-open" : "cursor-pointer"
            }`}
          >
            <th>
              <span
                className={`accordion-header ${open ? "" : "cursor-pointer"}`}
              >
                <span>
                  <span
                    className={`accordion-arrow ${
                      open ? "accordion-arrow-open" : "accordion-arrow-close"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      !isLoading && toggleAccordian();
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        !isLoading && toggleAccordian();
                      }
                    }}
                  >
                    {open ? <LeftArrowWhite /> : <OverlapingRectangles />}
                  </span>
                  <span
                    style={{
                      // ...(maxWidth > 0 && {
                      //   width: maxWidth + (isAtleastOnePowerStandard ? 30 : 0),
                      // }),
                      width: isAtleastOnePowerStandard ? "283px":"262px",
                      display:"flex"
                    }}
                    className={`report-number ${
                      open ? "" : "cursor-pointer label-color-close"
                    }`}
                  >
                    {isLoading ? (
                      <Skeleton
                        baseColor="#00000033"
                        highlightColor="#737373"
                        width={180}
                        height={22}
                        borderRadius={20}
                      />
                    ) : (
                      <>
                        <span> <ScrollingText elementClassName="report-number" elementStyles={{margin:"0px"}} tag="p" containerStyles={{maxWidth:"262px"}}>{name}</ScrollingText></span>

                        {power_standard && (
                          <span className="power-stander">
                            <PowerStandard />
                            <span className="power-tooltip">
                              This objective is a Power Standard
                            </span>
                          </span>
                        )}
                      </>
                    )}
                  </span>
                  {isLoading ? (
                    <Skeleton
                      baseColor="#00000033"
                      highlightColor="#737373"
                      width={140}
                      height={22}
                      borderRadius={8}
                      style={{ marginLeft: 23 }}
                    />
                  ) : (
                    <span
                      style={{ backgroundColor: scheduled_color, cursor: !pdClassroom.is_enabled?"auto":"pointer" }}
                      className={`report-date ${
                        isLoading  ? "" : "cursor-pointer"
                      } ${
                        auth.colorProfile?.background_theme === "GREY" &&
                        (scheduled_color === "#686868" ||
                          scheduled_color === "#424242")
                          ? "not-sch-dark"
                          : ""
                      }`}
                      onClick={(e) => { 
                              navigateToPacingCalendar(e);       
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                           navigateToPacingCalendar(e);
                        }
                      
                      }}
                    >
                      {scheduled_color === "#FFED7F" ||
                      scheduled_color === "#ffed7f" ? (
                        <CalenderIcon fill="white" stroke="#323233" />
                      ) : (
                        <CalenderIcon fill="#424242" stroke="white" />
                      )}
                      <p
                        style={{
                          color:
                            scheduled_color === "#424242" ||
                            scheduled_color === "#686868" ||
                            scheduled_color === "#3C809F" ||
                            scheduled_color === "#3c809f" ||
                            scheduled_color === "#3260D4" ||
                            scheduled_color === "#3260d4"
                              ? "#fff"
                              : "#323233",
                          textShadow:
                            scheduled_color === "#ffed7f" ||
                            scheduled_color === "#FFED7F"
                              ? "unset"
                              : "",
                        }}
                      >
                        {formattedDate}
                      </p>
                    </span>
                  )}
                </span>
                {open && (
                  <span className={`complete-toggle-container small-toggle`}>
                    <p>Completed</p>
                    <Toggle 
                      onChange={(e: any) => {
                        !isLoading && onMarkCompleted(e, formative);
                      }}
                      disabled = {isMarkCompletedDisabled || loading}
                      checked={completed}
                        
                    /> 
                  </span>
                )}
                <span
                  className={`report-bar-container ${
                    open ? "show" : "cursor-pointer"
                  }`}
                >
                  <CollapsedFormatives
                    loading={isLoading || formativeLoading}
                    formative={formative}
                    handleOnChangeToggleFormative={
                      handleOnChangeToggleFormative
                    }
                    onMarkCompleted={onMarkCompleted}
                    onClickRemediate={onClickRemediate}
                    completed={completed}
                  />
                </span>
              </span>
            </th>
          </thead>
          <tbody>
            <tr>
              <td
                className={`read-caption ${
                  open ? "accordion-open-caption" : "cursor-pointer"
                }`}
              >
                <span className="descWraper">
                  {isLoading ? (
                    <Skeleton
                      className="skeletonCaption"
                      baseColor="#00000033"
                      highlightColor="#737373"
                      width="70%"
                      height={22}
                      borderRadius={20}
                      style={{ display: "inline-block" }}
                    />
                  ) : (
                    <HtmlParser html={description} />
                  )}
                </span>
              </td>
            </tr>
            {open && (
              <tr onClick={(e) => e.stopPropagation()}>
                <td
                  className={`accordion-body-open ${
                    open ? "" : "accordion-body"
                  }`}
                >
                  <span
                    className={`books-container accordion-close ${
                      open ? "accordion-open" : ""
                    }`}
                  >
                    {lesson_guided_practice && (
                      <Book
                        bookNo={1}
                        title="Lesson/Guided Practice"
                        onClickStudentPdf={() =>
                          window.open(lesson_guided_practice.student_pdf)
                        }
                        onClickTeacherPdf={() =>
                          window.open(lesson_guided_practice.teacher_pdf)
                        }
                        teacherDisabled={!lesson_guided_practice.teacher_pdf}
                        studentDisabled={!lesson_guided_practice.student_pdf}
                      />
                    )}
                    {independent_practice && (
                      <Book
                        bookNo={2}
                        title="Independent Practice"
                        onClickStudentPdf={() =>
                          window.open(independent_practice.student_pdf)
                        }
                        onClickTeacherPdf={() =>
                          window.open(independent_practice.teacher_pdf)
                        }
                        teacherDisabled={!independent_practice.teacher_pdf}
                        studentDisabled={!independent_practice.student_pdf}
                      />
                    )}
                    {continuous_practice && (
                      <Book
                        bookNo={3}
                        title="Continuous Practice"
                        onClickStudentPdf={() =>
                          window.open(continuous_practice.student_pdf)
                        }
                        onClickTeacherPdf={() =>
                          window.open(continuous_practice.teacher_pdf)
                        }
                        teacherDisabled={!continuous_practice.teacher_pdf}
                        studentDisabled={!continuous_practice.student_pdf}
                      />
                    )}
                  </span>

                  <span
                    className={`books-container accordion-close ${
                      open ? "accordion-open" : ""
                    }`}
                  >
                    <Formative
                      formative={formative}
                      formativeLoading={formativeLoading}
                      handleOnChangeToggleFormative={
                        handleOnChangeToggleFormative
                      }
                      onClickRemediate={onClickRemediate}
                      scrollValue={scrollValue}
                    />
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {openNoStudentsModal && (
        <NoStudents
          isShow={openNoStudentsModal}
          onClose={() => setOpenNoStudentsModal(false)}
          loading={false}
        />
      )}

      {openRandomModal && (
        <ChooseQuestionOrder
          isShow={openRandomModal}
          onClose={onCancelChooseQuestionModal}
          onSave={onChangeRandom}
          isRandom={true}
          currentData={formative}
          loading={false}
          title={`Make ${formative.objective_number} Available to Students?`}
          description="Choose the order of questions below to make the assessment available in the Student Portal."
        />
      )}

      {!!showRemmediationModal && !showNewAttemptModal && (
        <RemediationModal
          ref={modalRef}
          onClose={() => {
            setShowRemmediationModal(null!);
            setOpenRemediateModal(false);
          }}
          onClickQuestionMark={() => {
            setNewAttemptInfoModal(true);
          }}
          fetchFormativeRemediateData={fetchFormativeRemediateData}
          formativeRemediateList={formativeRemediateList}
          formativeId={formativeId}
          reEnableAssessments={reEnableAssessments as any}
          classroomId={classroomId}
          onNewAttemptAssigned={() => {
            setShowNewAttemptModal(true);
          }}
        />
      )}

      {showNewAttemptModal && (
        <NewAttemptModal
          ref={newAttemptModalRef}
          isShow
          bodyText={`
                    The assessment has been re-enabled and new attempts have been assigned to the non-proficient students who have not yet 
                    completed 3 attempts and do not have an attempt in progress.
                  `}
          headerText={`${formativeRemediateList?.objective} New Attempts Assigned`}
          cancelButtonName="Okay"
          submitButtonName="Go to Monitor"
          onCancel={() => {
            setShowNewAttemptModal(false);
            setShowRemmediationModal(null!);
          }}
          type="success"
          onSubmit={() => {
            saveFormative(open, formative);
            navigateTo(assesment.formative, ReportsNavigation.MONITOR, formative);
            sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
            setShowRemmediationModal(null!);
          }}
        />
      )}

      {newAttemptInfoModal && (
        <NewAttemptInfoModal
          ref={infoModalRef}
          show
          closeModal={() => setNewAttemptInfoModal(false)}
        />
      )}
    </>
  );
};

export default Assessment;
